import React, { useEffect, useState } from "react";
import moment from "moment";

const RightSide = ({
  gameUsers,
  userBets,
  topBetsFound,
  cashoutData,
  cashoutShow,
  cashoutCount,
}) => {
  const [show, setShow] = useState(1);
  const [countUpdate, setCountUpdate] = useState("");
  const userLogin = localStorage.getItem("userToken");

  const counterEffect = (minimum, maximum) => {
    for (let count = minimum; count <= maximum; count++) {
      setTimeout(() => {
        setCountUpdate(count);
      }, 30 * count);
    }
  };

  useEffect(() => {
    counterEffect(0, gameUsers.length);
  }, [gameUsers.length]);

  return (
    <>
      <div className="col-lg-4 col-md-12 rightSide" data-aos={"fade-left"}>
        <div id="BetsSection" className="bets_sections">
          <div className={`winning-amount ${cashoutShow ? "active" : ""} `}>
            <div className="winning-amount-bet">
              <p>You man to pick up!</p>
              <h4>{cashoutCount}x</h4>
            </div>
            <div className="winning-amount-pick">
              <h5>{cashoutData} INR</h5>
              <p>Your Winnings</p>
            </div>
          </div>
          <div className="bets-tabs">
            <ul id="tabs-nav">
              <li
                className={`bets_tab ${show === 1 ? "active_bat_tab" : ""}`}
                onClick={() => setShow(1)}
              >
                <a>My Bets</a>
              </li>
              <li
                className={`bets_tab ${show === 2 ? "active_bat_tab" : ""}`}
                onClick={() => setShow(2)}
              >
                <a>Top</a>
              </li>
            </ul>
          </div>
          <div className="bts_section">
            {show === 0 && (
              <div className="bets_section_total tab-content" id="tab1">
                <div className="total_bets bets-title">
                  <h5>Total Bets - {countUpdate}</h5>
                  <div className="batting-btns-bar">
                  </div>
                </div>
                <div id="Battingtables">
                  <div className="batting_list_users">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">User</th>
                          <th scope="col">Bet</th>
                          <th scope="col">Collect</th>
                          <th scope="col">Win</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gameUsers &&
                          gameUsers.map((data, i) => (
                            <tr
                              key={i}
                              className={`${
                                data.cashout_number ? "winBat" : ""
                              } `}
                            >
                              <th scope="row" className="user_title">
                                <div
                                  className="userImg"
                                  style={{
                                    backgroundColor: `#${data.colorCode}`,
                                  }}
                                >
                                  {data.username.slice(0, 2)}
                                </div>
                                <span>{data.username}</span>
                              </th>

                              <td
                                className={
                                  data.betValue.length < 5 ? "" : "numberSets"
                                }
                              >
                                {data.betValue} INR
                              </td>
                              <td>
                                {data.cashout_number === undefined ? (
                                  "--"
                                ) : (
                                  <p>{data.cashout_number}x</p>
                                )}
                              </td>

                              <td className={"numberSets"}>
                                {data.cashout_number === undefined ? (
                                  "--"
                                ) : (
                                  <p
                                    className={
                                      (
                                        data.cashout_number * data.betValue
                                      ).toFixed(0).length < 5
                                        ? ""
                                        : "numberSets"
                                    }
                                  >
                                    {(
                                      data.cashout_number * data.betValue
                                    ).toFixed(2)}{" "}
                                    INR
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {show === 1 && (
              <>
                {userLogin ? (
                  <div className="bts_section">
                    <div className="bets_section_total" id="tab2">
                      <div className="total_bets bets-title">
                        {/* <h5>Total Win : ₹ {sum}.00</h5> */}
                      </div>
                      <div id="Battingtables">
                        <div className="batting_list_users">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Bet</th>
                                <th scope="col">Collect</th>
                                <th scope="col">Win</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userBets &&
                                userBets.map((data, i) => (
                                  <tr className="winBat" key={i}>
                                    <td>
                                      {moment(data.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>
                                    <td
                                      className={
                                        data.betValue.length < 5
                                          ? ""
                                          : "numberSets"
                                      }
                                    >
                                      {data.betValue}.00 INR
                                    </td>
                                    <td>
                                      {data.cashout_number === undefined ? (
                                        "--"
                                      ) : (
                                        <span>{data.cashout_number}x</span>
                                      )}
                                    </td>

                                    <td>
                                      {data.cashout_number === undefined ? (
                                        "0.00 INR"
                                      ) : (
                                        <span
                                          className={
                                            (
                                              data.cashout_number *
                                              data.betValue
                                            ).toFixed(0).length < 5
                                              ? ""
                                              : "numberSets"
                                          }
                                        >
                                          {(
                                            data.cashout_number * data.betValue
                                          ).toFixed(2)}
                                          INR
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {show === 2 && (
              <>
                <div
                  className="bets_section_total top_batting_section"
                  id="tab3"
                >
                  <div className="top_section_total top-content" id="daily">
                    <div className="topbatting_section">
                      {topBetsFound &&
                        topBetsFound.map((data, i) => (
                          <div className="bets_content" key={i}>
                            <div className="top_bets_content">
                              <div scope="row" className="user_title">
                                <div
                                  className="userImg"
                                  style={{
                                    backgroundColor: `#${data.colorCode}`,
                                  }}
                                >
                                  {data &&
                                    data.username &&
                                    data.username.slice(0, 2)}
                                </div>
                                <span className="user_title_name">
                                  {data.username}
                                </span>
                              </div>
                              <div className="Collect">
                                <h5>Collect</h5>
                                <h4>
                                  {" "}
                                  {data.cashout_number === undefined ? (
                                    "--"
                                  ) : (
                                    <span>{data.cashout_number}x</span>
                                  )}
                                </h4>
                              </div>
                              <button className="main-btn calendar-btn">
                                <img
                                  src="./images/calendar_top_vector.png"
                                  className="login-btn-img"
                                />{" "}
                                <span>
                                  {moment(data.createdAt).format("DD MMM YYYY")}
                                </span>
                              </button>
                            </div>
                            <div className="bottom_bets_content">
                              <div className="bet_detail">
                                <p>Bet</p>
                                <p
                                  className={
                                    data.betValue.length < 5
                                      ? "bet_amount"
                                      : "bet_amount numberSets"
                                  }
                                >
                                  {data.betValue} INR
                                </p>
                              </div>
                              <div className="bet_detail">
                                <p>Win</p>
                                <p
                                  className={
                                    (
                                      data.betValue * data.cashout_number
                                    ).toFixed(0).length < 5
                                      ? "bet_amount"
                                      : "bet_amount numberSets"
                                  }
                                >
                                  {data.cashout_number === undefined
                                    ? "0.00"
                                    : (
                                        parseFloat(data.betValue) *
                                        parseFloat(data.cashout_number)
                                      ).toFixed(2)}{" "}
                                  INR
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSide;
